import * as React from 'react';
import styles from './Sequence.module.css';
import classnames from 'classnames';

const Sequence = ({
  image,
  children,
  className,
  imageReplace,
}: {
  image?: string;
  children: React.ReactNode;
  className: string;
  imageReplace?: React.ReactNode;
}) => (
  <div className={classnames(styles.container, className)}>
    {imageReplace ? (
      <div className={styles.image}>{imageReplace}</div>
    ) : (
      <img src={image} alt="" className={styles.image} />
    )}
    <div className={styles.main}>{children}</div>
  </div>
);

export default Sequence;
