import * as React from 'react';
import _ from 'lodash';
import Sequence from './Sequence';
import sleep from './images/sleep.png';
import compete from './images/compete.png';
import kevryze from './images/kevryze.jpg';
import kevfeeder from './images/kevfeeder.jpg';
import kevOp from './images/kevOp.jpg';
import rome from './images/rome.jpg';
import meditations from './images/meditations.jpeg';
import spqr from './images/spqr.jpg';
import tenets from './images/tenets.png';
import looks from './images/looks.jpg';
import coke from './images/coke.jpg';
import steak from './images/steak.jpg';
import lego from './images/lego.jpg';
import flipflop from './images/flipflop.jpeg';
import shorts from './images/shorts.jpg';
import styles from './Sequences.module.css';

enum Steps {
  SLEEP,
  COMPETE,
  LEAGUE,
  SUMMONERS_RIFT,
  ARAM,
  ROME,
  TENETS,
  THICC,
  LOOKS,
  STARTERPACK,
}

const prevStep = (currentStep: Steps) => {
  return currentStep <= 0 ? Steps.STARTERPACK : currentStep - 1;
};
const nextStep = (currentStep: Steps) => {
  return currentStep >= Steps.STARTERPACK ? 0 : currentStep + 1;
};

const TextBlock = ({phrases}: {phrases: Array<string>}) => {
  return (
    <div className={styles.phraseContainer}>
      {phrases.map(phrase => (
        <div style={{marginTop: Math.random() * 140, marginLeft: Math.random() * 120}}>{phrase}</div>
      ))}
    </div>
  );
};

const StarterPackText = ({count = 10, className}: {count?: number; className?: string}) => {
  return (
    <div className={className}>
      {_.range(count).map(_ => (
        <div>starter pec</div>
      ))}
    </div>
  );
};

const Sequences = () => {
  const [step, setStep] = React.useState(Steps.SLEEP);
  React.useEffect(() => {
    const setNextStep = () => setStep(nextStep(step));
    const onKeyPress = (e: KeyboardEvent) => {
      switch (e.keyCode) {
        case 37:
          setStep(prevStep(step));
          break;
        case 39:
          setStep(nextStep(step));
          break;
      }
    };
    window.addEventListener('click', setNextStep);
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('click', setNextStep);
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [step]);

  switch (step) {
    case Steps.SLEEP:
      return (
        <Sequence className={styles.sleep} image={sleep}>
          <TextBlock phrases={['i sleep', 'i good dude', 'i luv doggo', 'im single ladies']} />
        </Sequence>
      );
    case Steps.COMPETE:
      return (
        <Sequence className={styles.compete} image={compete}>
          <TextBlock
            phrases={['i love booty calls', 'i am competitive', 'i try hard', 'i work out', 'i play until i win']}
          />
        </Sequence>
      );
    case Steps.LEAGUE:
      return (
        <Sequence className={styles.league} image={kevryze}>
          <TextBlock
            phrases={['i love booty calls', 'i am competitive', 'i try hard', 'i work out', 'i play until i win']}
          />
          <div className={styles.inLeagueText}>IN LEAGUE</div>
        </Sequence>
      );
    case Steps.SUMMONERS_RIFT:
      return (
        <Sequence className={styles.srb} image={kevfeeder}>
          <div className={styles.leagueMaps}>
            My RYZE in <span className={styles.summonersRift}>SUMMONERS RIFT</span>
            <br />
            😡😡😡
          </div>
        </Sequence>
      );
    case Steps.ARAM:
      return (
        <Sequence className={styles.aramb} image={kevOp}>
          <div className={styles.leagueMaps}>
            My RYZE in <span className={styles.aram}>ARAM</span>
            <br />
            😎😎😎
          </div>
        </Sequence>
      );
    case Steps.ROME:
      return (
        <Sequence className={styles.rome} image={rome}>
          <TextBlock
            phrases={['i am a principled \nman', 'i live by a set of tenets', 'just like my heros of ancient rome']}
          />
          <img src={spqr} className={styles.spqr} />
        </Sequence>
      );
    case Steps.TENETS:
      return (
        <Sequence className={styles.tenets} image={tenets}>
          <TextBlock
            phrases={['i am a principled \nman', 'i live by a set of tenets', 'just like my heros of ancient rome']}
          />
          <img src={meditations} className={styles.spqr} />
        </Sequence>
      );
    case Steps.THICC:
      return (
        <Sequence
          className={styles.thicc}
          imageReplace={
            <video
              className={styles.video}
              playsinline
              autoPlay
              poster="https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/e35/46534796_2225578071045129_4934512978478832595_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&amp;_nc_cat=109&amp;_nc_ohc=mRr2kcYk0t0AX9CA1a-&amp;oh=3c8369f1b3a828875a91209ba4fdd15f&amp;oe=5E1E138D"
              preload="none"
              // @ts-ignore
              type="video/mp4"
              src="https://scontent-sjc3-1.cdninstagram.com/v/t50.2886-16/48737645_372387490232710_6265970835224252009_n.mp4?_nc_ht=scontent-sjc3-1.cdninstagram.com&amp;_nc_cat=110&amp;_nc_ohc=zk38WTiNBmQAX8G92WF&amp;oe=5E1E351A&amp;oh=bb015f2892cb5a877517bbe149a6b43f"
              loop={false}></video>
          }>
          <TextBlock phrases={['i work out', 'i have thicc thighs', 'that leg tho', 'if you know it you know']} />
          <div className={styles.biceps}>💪💪💪</div>
        </Sequence>
      );
    case Steps.LOOKS:
      return (
        <Sequence className={styles.looks} image={looks}>
          <TextBlock
            phrases={['im well groomed', 'i put a lot of effort\n inyo my appearance', 'men’s fashion \nis my passion']}
          />
        </Sequence>
      );
    case Steps.STARTERPACK:
      return (
        <Sequence className={styles.starterpack} imageReplace={null}>
          <div className={styles.starterPackContent}>
            <StarterPackText />
            <img src={coke} className={styles.coke} />
            <img src={lego} className={styles.lego} />
            <img src={steak} className={styles.steak} />
            <img src={shorts} className={styles.shorts} />
            <img src={flipflop} className={styles.flipflop} />
            <StarterPackText className={styles.sp2} />
          </div>
        </Sequence>
      );
  }
};

export default Sequences;
