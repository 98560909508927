import React from 'react';
import styles from './App.module.css';
import Sequences from './Sequences';
import tindr from './images/tindr.png';
import shootyourshot from './images/shootyourshot.png';

const App: React.FC = () => {
  return (
    <div className={styles.app}>
      <Sequences />
      <img src={tindr} alt="tindr" className={styles.tindr} />
      <div className={styles.mainContainer}>
        <h1 className={styles.kovath}>Kovath, 26</h1>
        <a href="//www.instagram.com/kovathy/" className={styles.shootyourshotLink}>
          <div className={styles.shootyourshotcontainer}>
            <img src={shootyourshot} alt="shootshot" />
            <div className={styles.textShoot}>shoot your shot</div>
            <div className={styles.textLeague}>on league of legends</div>
          </div>
        </a>
        <div className={styles.slideIntoDMS}>Slide into my DM's baby</div>
      </div>
    </div>
  );
};

export default App;
